import React from 'react';

const NotFound = () => {
    document.title = 'Not found - Just travel blog';
    return (
        <div className="container">
            <div className="notfound">
                <h1>ERROR 404 - Not found</h1>
            </div>

        </div>
    );
};

export default NotFound;